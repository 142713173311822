<!-- 水电余额交易记录 -->
<template>
  <a-card :bordered="false">
    <div class="summary">个人水电余额： {{ balance }}元</div>
    <div class="summary" v-if="showSummaryDetail">
      累计充值金额：{{ addValueTotal }}元<span class="padding"/>累计扣费金额：{{ deductionTotal }}元
    </div>
    <a-spin :spinning="dataLoading">
      <div style="margin: 0px -24px 24px">
        <a-card
          style="width: 100%"
          :bordered="false"
          :tabList="historyTabs"
          :activeTabKey="tabTitleKey"
          @tabChange="(key) => (this.tabTitleKey = key)"
        ></a-card>
      </div>
      <div>
        <utilityTransactionTableDisplay
          key="1"
          v-if="accountId && tabTitleKey === 'addValueHistory'"
          :columnHeader="addValueColumns"
          rowKey="order_code"
          :endpoint="addValueOrdersQueryEndpoint"
          :accountId="accountId"
          :getTableDataFunc="getAddValueTableData"
        />
        <utilityTransactionTableDisplay
          key="2"
          v-if="accountId && tabTitleKey === 'deductionHistory'"
          :columnHeader="deductionColumns"
          rowKey="record_id"
          :endpoint="deductionRecordsQueryEndpoint"
          :accountId="accountId"
          :getTableDataFunc="getDeductionTableData"
        />
      </div>
    </a-spin>
  </a-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import utilityTransactionTableDisplay from "./utilityTransactionTableDisplay";

export default {
  name: "utilityBalanceTransactionHistory",
  props: {
    contractId: Number,
    utilityAccountId: String,
    balanceAmount: Number,
    showSummaryDetail: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    utilityTransactionTableDisplay,
  },
  data() {
    return {
      dataLoading: false,
      accountId: null,
      balance: 0.0,
      addValueOrdersQueryEndpoint: this.$endpoints.FINANCE_GET_UTILITY_BALANCE_ADD_VALUE_ORDERS,
      deductionRecordsQueryEndpoint: this.$endpoints.FINANCE_GET_UTILITY_BALANCE_DEDUCTION_RECORDS,
      tabTitleKey: "addValueHistory",
      addValueTotal: 0.0,
      deductionTotal: 0.0,

      historyTabs: [
        {key: "addValueHistory", tab: "充值记录"},
        {key: "deductionHistory", tab: "扣费记录"}
      ],
      // 充值记录
      addValueColumns: [
        {
          title: "充值方式",
          width: 100,
          dataIndex: "method",
          key: "method",
          align: "center",
        },
        {
          title: "充值金额（元）",
          width: 100,
          dataIndex: "amount",
          key: "amount",
          align: "center",
        },
        {
          title: "充值时间",
          width: 160,
          dataIndex: "timestamp",
          key: "timestamp",
          align: "center",
        },
        {
          title: "充值订单号",
          width: 100,
          dataIndex: "order_code",
          key: "order_code",
          align: "center",
        },
      ],

      // 扣费记录
      deductionColumns: [
        {
          title: "扣费设备",
          width: 100,
          dataIndex: "meter_code",
          key: "meter_code",
          align: "center",
        },
        {
          title: "初始读数",
          width: 100,
          dataIndex: "start",
          key: "start",
          align: "center",
        },
        {
          title: "最后读数",
          width: 100,
          dataIndex: "end",
          key: "end",
          align: "center",
        },
        {
          title: "使用量",
          width: 100,
          dataIndex: "usage",
          key: "usage",
          align: "center",
        },
        {
          title: "扣费金额（含税价）",
          width: 120,
          dataIndex: "cost",
          key: "cost",
          align: "center",
        },
        {
          title: "税点",
          width: 100,
          dataIndex: "tax_rate",
          key: "tax_rate",
          align: "center",
        },
        {
          title: "扣费金额（不含税价）",
          width: 120,
          dataIndex: "cost_tax_exclusive",
          key: "cost_tax_exclusive",
          align: "center",
        },
        {
          title: "扣费时间",
          width: 160,
          dataIndex: "timestamp",
          key: "timestamp",
          align: "center",
        },
      ],
    };
  },
  methods: {
    init() {
      this.balance = this.balanceAmount;
      this.getAccountId().then(() => {
        this.getSummaryDetail();
      });
    },

    async getAccountId() {
      if (this.utilityAccountId) {
        this.accountId = this.utilityAccountId;
        return;
      }

      this.dataLoading = true;

      await axios({
        url: this.$endpoints.OG_ACCOUNT_GET_UTILITY_PREPAY_ACCOUNT_INFO,
        method: "GET",
        params: {
          contract_ids: this.contractId,
          page: 1,
          page_size: 10,
        }
      }).then((res) => {
        if (res.status == 200) {
          this.accountId = res.data.accounts[0].id;
          this.balance = this.moneyToAmount(res.data.accounts[0].balance).toFixed(2);
        }
        else {
          this.accountId = null;
          this.$message.error("获取合同预付费账号错误" + `${res.statusText}`);
        }
        this.dataLoading = false;
      })
    },

    getSummaryDetail() {
      if (!this.showSummaryDetail || !this.accountId) {
        return;
      }

      this.dataLoading = true;

      axios({
        url: this.$endpoints.FINANCE_GET_UTILITY_BALANCE_TRANSACTION_HISTORY,
        method: "GET",
        params: {
          account_id: this.accountId,
          start_time: moment().subtract(10, 'years').toISOString(),
          end_time: moment().toISOString(),
          page_size: 10000,
          page: 1,
        }
      }).then((res) => {
        if (res.status == 200) {
          const addValueTotal = res.data.logs
            .filter(l => l.type == "Recharge")
            .reduce((subtotal, current) => subtotal + this.moneyToAmount(current.value), 0.0);
          this.addValueTotal = addValueTotal.toFixed(2);

          const deductionTotal = res.data.logs
            .filter(l => l.type == "DailyCost")
            .reduce((subtotal, current) => subtotal + this.moneyToAmount(current.value), 0.0);
          this.deductionTotal = deductionTotal.toFixed(2);

          this.balance = this.balance || this.addValueTotal - this.deductionTotal;
        }
        else {
          this.addValueTotal = 0.0;
          this.deductionTotal = 0.0;
          this.balance = this.balance || 0.0;
          this.$message.error("获取充值记录错误" + `${res.statusText}`, 3);
        }
        this.dataLoading = false;
      })
    },

    moneyToAmount(m) {
      return m.units + m.nanos / (10 ** 9);
    },

    getAddValueTableData(resData) {
      return resData.orders.filter(o => o.status == "PAID").map(o => {
        return {
          method: "水电预充值",
          amount: this.moneyToAmount(o.amount).toFixed(2),
          timestamp: moment(o.createdAt).format("YYYY-MM-DD HH:mm"),
          order_code: o.code,
        };
      });
    },

    getDeductionTableData(resData) {
      let i = 0;
      return resData.records.map(r => {
        return {
          record_id: i++,
          meter_code: r.meterCode,
          start: r.start,
          end: r.end,
          usage: (parseFloat(r.end) - parseFloat(r.start)).toFixed(2),
          cost: this.moneyToAmount(r.cost).toFixed(2),
          tax_rate: r.taxRate,
          cost_tax_exclusive: this.moneyToAmount(r.costTaxExclusive).toFixed(2),
          timestamp: moment(r.timestamp).format("YYYY-MM-DD HH:mm"),
        };
      });
    },
  },
  created() {
    this.init();
  },
};
</script>
<style lang="less">
.summary {
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: bold;
}

.padding {
  padding-left: 6px;
  padding-right: 6px;
}
</style>