<template>
  <div>
    <a-card>
      <a-spin :spinning="dataLoading">
        <a-table
          :rowKey="rowKey"
          :pagination="pagination"
          :columns="columnHeader"
          :dataSource="tableData"
          :scroll="{ x: 1200 }"
          @change="onTableChange"
        >
        </a-table>
      </a-spin>
    </a-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { defaultPagination } from "../../../utils/pagination";
export default {
  name: "utilityTransactionTableDisplay",
  props: {
    columnHeader: Array,
    rowKey: String,
    // This endpoint will be called to get data displayed by the table
    endpoint: String,
    // parameters userd to call endpoint
    accountId: String,
    // This function will be called to transform data returned from the endpoint
    // input argument: response data
    // output: list of records
    getTableDataFunc: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      pagination: defaultPagination(),
      tableData: [],
      dataLoading: false,
    };
  },
  methods: {
    init() {
      this.pagination.pageSize = 20;
      this.pagination.current = 1;

      this.loadTable();
    },

    loadTable() {
      this.tableData = [];
      this.dataLoading = true;

      axios({
        url: this.endpoint,
        method: "GET",
        params: {
          page_size: this.pagination.pageSize,
          page: this.pagination.current,
          account_ids: this.accountId,
          start_time: moment().subtract(10, 'years').toISOString(),
          end_time: moment().toISOString(),
        },
      }).then((res) => {
        if (res.status == 200) {
          this.tableData = this.getTableDataFunc(res.data) || [];
          this.pagination.total = res.data.totalCount;
        } else {
          this.tableData = [];
          this.pagination.total = null;
          this.pagination.current = 1;
          this.$message.error("请求交易记录数据错误" + `${res.statusText}`);
        }
        this.dataLoading = false;
      });
    },

    onTableChange(pagination) {
      this.pagination.current = pagination.current;

      this.loadTable();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style>
</style>